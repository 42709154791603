import React, { useRef } from 'react';

import {
  Box,
  List,
  Paper,
  Divider,
  ListItem,
  Container,
  Typography,
  ListItemText,
} from '@mui/material';

export default function Privacypolicy() {
  const sectionRefs = {
    intro: useRef(null),
    terms: useRef(null),
    privacy: useRef(null),
    first: useRef(null),
    second: useRef(null),
    third: useRef(null),
    fourth: useRef(null),
    fifth: useRef(null),
    sixth: useRef(null),
    cookies: useRef(null),
    disclaimer: useRef(null),
  };
  return (
    <Container maxWidth="lg" sx={{ display: 'flex', py: 4 }}>
      {/* Sidebar */}
      <Box
        sx={{
          width: '25%',
          pr: 2,
          position: 'sticky',
          top: 20,
          height: 'fit-content',
          maxHeight: 'calc(100vh - 40px)',
          overflowY: 'auto',
          zIndex: 1,
          display: { md: 'block', xs: 'none' },
        }}
      >
        <Paper elevation={3} sx={{ p: 2, backgroundColor: 'rgba(219, 255, 247, 0.37)' }}>
          <Typography variant="h6">
            السياسات المتعلقة بالمستخدمين الباحثين عن الخدمات الطبية (مرضى و العائلات)
          </Typography>
          <Divider sx={{ my: 2 }} />
          <List>
            <ListItem
              button
              onClick={() => sectionRefs.intro.current?.scrollIntoView({ behavior: 'smooth' })}
            >
              <ListItemText primary="تعريف المصطلحات" />
            </ListItem>
            <ListItem
              button
              onClick={() => sectionRefs.privacy.current?.scrollIntoView({ behavior: 'smooth' })}
            >
              <ListItemText primary="سياسات الخصوصية" />
            </ListItem>
            <List component="div" disablePadding sx={{ pl: 2 }}>
              <ListItem
                button
                onClick={() => sectionRefs.first.current?.scrollIntoView({ behavior: 'smooth' })}
              >
                <ListItemText primary="أولاً: المعلومات التي تُخزن في المنصة" />
              </ListItem>

              <ListItem
                button
                onClick={() => sectionRefs.second.current?.scrollIntoView({ behavior: 'smooth' })}
              >
                <ListItemText primary="ثانياً: كيفية استخدام المعلومات التي يقوم المستخدم الطرف الثاني بتخزينها في المنصة" />
              </ListItem>

              <ListItem
                button
                onClick={() => sectionRefs.third.current?.scrollIntoView({ behavior: 'smooth' })}
              >
                <ListItemText primary="ثالثاً: ملكية البيانات وحمايتها" />
              </ListItem>

              <ListItem
                button
                onClick={() => sectionRefs.fourth.current?.scrollIntoView({ behavior: 'smooth' })}
              >
                <ListItemText primary="رابعاً: حماية البيانات، الاختراق الإلكتروني ومخاطر أخرى" />
              </ListItem>

              <ListItem
                button
                onClick={() => sectionRefs.fifth.current?.scrollIntoView({ behavior: 'smooth' })}
              >
                <ListItemText primary="خامساً: استخدام البيانات والانتفاع منها" />
              </ListItem>
              <ListItem
                button
                onClick={() => sectionRefs.sixth.current?.scrollIntoView({ behavior: 'smooth' })}
              >
                <ListItemText primary="سادساً: التغييرات في سياسة خصوصية هذا الموقع" />
              </ListItem>
              <ListItem button>
                <ListItemText
                  primary="سياسة cookies"
                  onClick={() =>
                    sectionRefs.cookies.current?.scrollIntoView({ behavior: 'smooth' })
                  }
                />
              </ListItem>
            </List>
          </List>
        </Paper>
      </Box>

      {/* Main Content */}
      <Box sx={{ width: { md: '75%', xs: '100%' } }}>
        <Typography variant="h5" align="center" gutterBottom ref={sectionRefs.intro}>
          السياسات المتعلقة بمستخدمي الخدمات الطبية (مرضى و العائلات)
        </Typography>
        <Paper elevation={3} sx={{ p: 3, backgroundColor: '#E4F6F2' }}>
          <Box>
            <Typography variant="body1" paragraph>
              الرجاء قراءة سياسة الخصوصية بعناية مع استيعاب كل البنود قبل التوقيع على قبولها و قبل
              استخدام الموقع Hakeemna.com أو التطبيقات المرتبطة بها أو الصفحات ذو علاقة بالمنصة.
            </Typography>

            <Typography variant="h6" gutterBottom>
              تعريف المصطلحات
            </Typography>
            <Typography variant="body1" paragraph>
              <strong>الطرف الأول:</strong> هو منصة حكيمنا. كوم. عندما يتم ذكر مصطلح المنصة أو
              حكيمنا.كوم أو Hakeemna.com أو نظام في هذا القسم فإنه يشمل المنصة الإلكترونية،
              التطبيقات على Android أو IOS، المواقع الإلكترونية ذو علاقة مع المنصة، المدراء و
              الموظفين العاملين في المنصة.
            </Typography>
            <Typography variant="body1" paragraph>
              <strong>الطرف الثاني:</strong> هو المستخدم والمستفيد من الخدمات الطبية المقدمة من
              الممارسين لمهنة الطب و الموردين للخدمات ذات علاقة بالقطاع الصحي، حيث أن مصطلح المستخدم
              أو المستفيد يشمل المريض وعائلته التي تستخدم المنصة، أو متصفح زائر للمنصة.
            </Typography>
            <Typography variant="body1" paragraph>
              <strong>الطرف الثالث:</strong> هو مزود خدمة طبية، مثل ممارس مهنة الطب مثل عيادة، مركز
              صحي، مركز أشعة، طبيب نفسي، أو مؤسسة مستلزمات طبية وغيرهم ممن يقومون بتزويد خدمات لها
              علاقة بالقطاع الصحي للطرف الثاني، أي أن الطرف الثالث هو أي شخص طبيعي أو اعتباري ومرخص
              له حسب القانون في بلد إقامته في العمل بشكل مباشر أو غير مباشر في القطاع الصحي.
            </Typography>

            <Typography variant="h6" gutterBottom ref={sectionRefs.privacy}>
              سياسة الخصوصية
            </Typography>
            <Typography variant="body1" paragraph>
              إدارة Hakeemna.com تقدر أهمية المحافظة على خصوصية وسرية معلومات المستخدمين الباحثين عن
              الخدمات الطبية، لذلك عندما يقوم المستخدم بإدخال معلوماته في المنصة فإنه يوجد آليات
              وقوانين للحفاظ على تلك الخصوصية.
            </Typography>
            <Typography variant="body1" paragraph>
              يتناول هذا القسم سياسة وأحكام استخدامك لموقع Hakeemna.com وأي خدمات وتطبيقات ذات صلة،
              بما في ذلك التعليم والتدريب الخاص بالمنصة، وينطبق ذلك أيضًا على أي معلومات يتم جمعها
              عنك بما في ذلك عند حضور فعاليات تخص المنصة. قد نقدم أيضًا إشعارات حول سياسات خصوصية
              مختلفة أو إضافية فيما يتعلق ببعض الأنشطة والبرامج والعروض، بما في ذلك إشعارات إضافية
              في الوقت المناسب والتي قد تكمل أو توضح ممارسات الخصوصية لدينا أو تزودك بخيارات إضافية
              فيما يتعلق بمعلوماتك الشخصية.
            </Typography>
            <Typography variant="body1" paragraph>
              في القسم التالي نقوم بتوضيح سياسات الخصوصية:
            </Typography>
            <Typography variant="subtitle1" gutterBottom ref={sectionRefs.first}>
              أولاً، المعلومات التي يتم تخزينها في المنصة:
            </Typography>
            <Typography variant="body1" paragraph>
              أ) معلومات يقوم المستخدم (الطرف الثاني) بإدخالها في المنصة: معلومات عامة عن المستخدم
              (مثل المريض) وهي إلزامية عند التسجيل لأول مرة، مثل: الاسم الثلاثي، الرقم الوطني تاريخ
              الميلاد، رقم الهاتف، البريد الالكتروني،الجنسية، و مقر الإقامة. معلومات إضافية عامة
              (غير إلزامية) مثل: الطول، الوزن، ممارسة الرياضة ، الامراض المزمنة و صورة شخصية. شركة
              التأمين المتعاقد معها، معلومات أفراد الأسرة في حال كنت ولي أمرهم وقمت بربط حسابهم
              الشخصي مع حسابك، و معلومات طبية غير الزامية مثل: الأمراض المزمنة، التاريخ المرضي،
              الأدوية التي يتناولها، تقارير ومستندات طبية مختلفة. المستخدم مسؤول بشكل قانوني عن صحة
              البيانات التي يقوم بإدخالها في النظام ويتحمل التبعات القانونية في حال قام بإدخال
              بيانات غير صحيحة أو بعمل حركات مزيفة للتأثير على المنصة أو على بقية المستخدمين للمنصة،
              سواء كان ذلك المستخدم هو مستفيد من الخدمات أو مزود لتلك الخدمات الطبية.
            </Typography>

            <Typography variant="body1" paragraph>
              ب) معلومات يتم إدخالها من أطراف أخرى (مثل الأطباء) ولها علاقة بالمستخدم للخدمات الطبية
              و أفراد عائلته، مثل المعلومات الطبية، الوصفات الطبية، إجازات مرضية،.
            </Typography>

            <Typography variant="body1" paragraph>
              ج) معلومات يتم جمعها من خلال الاستبيانات في حال استدعى ذلك الحصول على المعلومات
              التعريفية حول المستخدم للمنصة، مع العلم أن المشاركة في الاستبيانات هو اختياري وليس
              الزامي.
            </Typography>

            <Typography variant="body1" paragraph>
              د) معلومات حول شبكة الانترنت التي تستخدمها، مثل IP Address.
            </Typography>

            <Typography variant="subtitle1" gutterBottom ref={sectionRefs.second}>
              ثانياً: كيفية استخدام المعلومات التي يقوم المستخدم &quot;الطرف الثاني&quot; بتخزينها
              في المنصة:
            </Typography>

            <Typography variant="body1" paragraph>
              البيانات التي قمت أنت بإدخالها و تخزينها تستخدم بهدف تزويدك بالخدمات المختلفة التي
              تقدمها منصة Hakeemna.com مثل حجز مواعيد، تقارير طبية، التاريخ المرضي، التواصل مع
              الأطباء و الموردين. عند إدخالك للبيانات و تخزينها في منصة Hakeemna.com فإنك توافق على
              استخدام تلك البيانات للغايات والخدمات التي تقدمها المنصة. عند إدخال بيانات التواصل مثل
              رقم الهاتف والبريد الإلكتروني فإنك توافق على استلام اشعارات ورسائل بريد إلكتروني من
              منصة Hakeemna.com كذلك تستطيع الغاءها (الاشعارات واستلام الرسائل الإلكترونية) بشكل
              جزئي حسب مضمون الاشعار أو الرسالة. منصة Hakeemna.com لها الأحقية بحذف أي بيانات يقوم
              المستخدم بإدخالها على المنصة قد تعتبر جريمة ضمن قانون الجرائم الالكترونية أو قد تنتهك
              حقوق ملكية شخص معنوي أو اعتباري أو حقوق منصتنا، لذلك للمنصة الحق بمتابعة ومراقبة
              المحتوى الذي يقوم بنشره جميع الأطراف المستخدمة للمنصة (الطرف الثاني و الطرق الثالث)،
              مع العلم أن هذه المتابعة والمراقبة غير إلزامي عملها بشكل دوري. في حال قام المستخدم
              بإغلاق حسابه في المنصة، فإنه يحق لمنصة Hakeemna.com الاحتفاظ بالبيانات لغايات حفظ
              السجلات والمعلومات التاريخية والأرشفة. كذلك وبعد الحفاظ على سرية المستخدم وخصوصيته،
              يحق للمنصة الاستفادة والانتفاع من تلك البيانات لغاية الدراسات و البحث العلمي أو لأي
              أهداف أخرى.
            </Typography>

            <Typography variant="subtitle1" gutterBottom ref={sectionRefs.third}>
              ثالثاً: ملكية البيانات وحمايتها
            </Typography>

            <Typography variant="body1" paragraph>
              المعلومات التي قمت بتخزينها و ملفك الطبي المتاح في صفحتك الشخصية على المنصة هي من
              الممتلكات الخاصة بك، تستطيع مشاركة تلك المعلومات مع أي مزود للخدمات الطبية في بلدك أو
              في أي بلد آخر. عند موافقتك على سياسة الخصوصية الخاصة بمنصة Hakeemna.com فإنك توافق
              علــى: في حال قمت بأخذ موعد لدى مزود خدمة طبي، فإنك موافق على أن نقوم بمشاركة معلوماتك
              لمزود الخدمة الطبي الذي اخذت موعد عنده في منصتنا. جزء من بياناتك الطبية المخزنة في
              منصة Hakeemna.com قد تكون مؤرشفة في السجلات الالكترونية لدى الطرف الثالث (مزودي الخدمة
              الطبية مثل عيادة طبية أو مركز صحي) وقد لا يكون عندك صلاحيات لتصفحها، في حال رغبت
              بالحصول على نسخة منها فعليك طلب منهم تزويدك بنسخة من تلك البيانات و ليس من منصة
              Hakeemna.com، مع العلم أن تخزين تلك البيانات، تنظيمها وحمايتها هي من مسؤولية الطرف
              الثالث (الممارسين و مزودي الخدمة الطبية ) وليست من مسؤولية منصة Hakeemna.com.
            </Typography>

            <Typography variant="body1" paragraph>
              في ما يخص البيانات و المعلومات المخزنة لدى الطرف الثالث (مزودي الخدمات الطبية، مثلاً
              عيادة) في منصة Hakeemna.com، قد يكون لديهم سياسات وقوانين خصوصية مختلفة عن منصة
              Hakeemna.com، وبالتالي فإن مشاركة معلوماتهم مع المستخدم (الطرف الثاني) تخضع لقوانين
              الدولة التي يعمل فيها مزود الخدمة الطبية مع الأخذ بعين الاعتبار قوانينه الخاصة
              المتعلقة بالخصوصية. البيانات الشخصية المشمولة بسياسة الخصوصية و تقوم انت بالكشف عنها
              بشكل طوعي عن طريق نشرها في المنصة تصبح متاحة للجميع. يرجى العلم أنه في حالة قمت بحذف
              تلك المعلومات فقد يوجد نسخة منها في البيانات المؤرشفة لدينا كبيانات احتياطية في
              المنصة. كذلك الحال عندما تقوم بمشاركة بيانات تتصف بالخصوصية مع أطراف أخرى، فإنه ينعدم
              عنها صفة الخصوصية ويتحمل الفاعل المسؤولية والمخاطر الناجمة عن تلك المشاركة. في حال
              تطلب القانون او أي اجراء قضائي الكشف عن المعلومات الشخصية للمستخدم أو معلومات أخرى
              عنه، فإن منصة Hakeemna.com ملزمة بتطبيق القانون وتزويدهم بكل المعلومات المطلوبة لتحقيق
              العدالة.
            </Typography>
            <Typography variant="subtitle1" gutterBottom ref={sectionRefs.fourth}>
              رابعاً: حماية البيانات، الاختراق الإلكتروني ومخاطر أخرى
            </Typography>

            <Typography variant="body1" paragraph>
              إن أمان معلوماتك أمر مهم بالنسبة لنا، لذلك يجب معرفة أنه يتم الاحتفاظ بالمعلومات
              الشخصية على خوادم خارج مقر إدارة ومنصة Hakeemna.com (المملكة الأردنية الهاشمية)، وقد
              يتم الاستعانة بخدمات تخزين البيانات في شركات لها خوادم خارج الأردن، وبالتالي فإن عند
              موافقتك على سياسة الخصوصية فأنت على علم وتوافق على التالي:
            </Typography>

            <Typography variant="body1" paragraph>
              منصة حكيمنا تعمل على جمع البيانات وتخزينها ومعالجتها في خوادم خارجية، لذلك تقوم المنصة
              بتطبيق قواعد أمنية لحماية المعلومات ضد الدخول غير مرخص له، لكن و بحكم وجود مخاطر
              متعددة فإننا ملتزمون باتخاذ كل الاجراءات الممكنة لمنع الاختراقات لكن لا نتعهد بأي شكل
              من الأشكال منع الاختراقات و منع القرصنة وسرقة البيانات.
            </Typography>

            <Typography variant="body1" paragraph>
              إذا قدمت معلومات شخصية إلينا، فقد تتم معالجة معلوماتك الشخصية في بلد أجنبي، حيث قد
              تكون قوانين الخصوصية أقل صرامة. من خلال تقديم معلوماتك الشخصية إلينا، فإنك توافق على
              نقل معلوماتك الشخصية وتخزينها ومعالجتها في بلد آخر غير مقر إدارة منصة حكيمنا و في بلد
              غير مكان إقامتك.
            </Typography>

            <Typography variant="body1" paragraph>
              المستخدم مسؤول عن حماية كلمة السر الخاصة به للدخول الى النظام، كذلك مسؤول عن حفظ نسخة
              احتياطية من بياناته ومعلوماته المخزنة في المنصة، مع العلم أن تخزين نسخة احتياطية عن
              بياناتك ليست من مسؤولية المنصة Hakeemna.com.
            </Typography>

            <Typography variant="body1" paragraph>
              لن تتحمل منصة Hakeemna.com مسؤولية التعويض بأي شكل من الأشكال عن الأضرار التي قد تنجم
              من استخدام للموقع أو أحد الخدمات المرتبطة به أو فقدان للبيانات، ومنه فإن المنصة ليست
              مسؤولة قانونياً ولا مالياً عن الأضرار الناجمة عن:
            </Typography>

            <Typography variant="body1" paragraph>
              سوء الاستخدام الناتج من الطرف الثاني (المستخدمين للمنصة) وما يترتب عليه من أضرار، كذلك
              لا تتحمل الأضرار الناجمة عن سوء استخدام الطرف الثالث للمنصة أو أي تطبيق له علاقة معنا.
            </Typography>

            <Typography variant="body1" paragraph>
              الحملات الترويجية التي يقوم الموردون في نفس المنصة و النتيجة المترتبة عليها،
            </Typography>

            <Typography variant="body1" paragraph>
              معلومات قام بنشرها مستخدمين (الطرف الثاني) على المنصة وليست صحيحة أو على مواقع أخرى،
              كذلك الحال بالنسبة للطرف الثالث.
            </Typography>

            <Typography variant="body1" paragraph>
              المنصة ليست مسؤولة عن الأخطاء الطبية أو غيرها من الأضرار المباشرة او غير مباشرة من
              مزودي الخدمات الطبية أو من أي طرف ثالث. كذلك الحال بالنسبة لما يتم نشره من معلومات على
              المنصة أو تطبيق له علاقة بالمنصة، فإننا لسنا مسؤولون عما يتم نشره من قبل الطرف الثاني
              أو الثالث.
            </Typography>

            <Typography variant="body1" paragraph>
              في حال حدوث اختراق أمني وتم اكتشافه و السيطرة عليه، فإنه سوف يتم إشعار كل من تم اختراق
              بياناته الشخصية بواسطة طريقة التواصل المخزنة في النظام.
            </Typography>

            <Typography variant="body1" paragraph>
              على المستخدم ابلاغ ادارة منصة Hakeemna.com عن أي حالة او محاولة سرقة لهويته أو معلومات
              الدخول الخاصة به في المنصة.
            </Typography>

            <Typography variant="body1" paragraph>
              لأغراض أمنية، قد نطلب منك معلومات إضافية للتحقق من هويتك حتى نتمكن من معالجة بعض
              الطلبات. في مثل هذه الحالات، قد نتصل بك عبر البريد الإلكتروني أو بأي وسيلة أخرى متاحة
              للتحقق من هويتك و من طلبك.
            </Typography>

            <Typography variant="body1" paragraph>
              <strong>خصوصية الأطفال:</strong> المنصة ليست مخصصة للأطفال والمراهقين الذين تقل
              أعمارهم عن 18 عامًا. نحن لا نجمع معلومات شخصية عن عمد عن الذين تقل أعمارهم عن 18 عامًا
              دون موافقة الوالدين. إذا علمنا أن طفلاً/ مراهق يقل عمره عن 18 عامًا قد زودنا بمعلومات
              شخصية من خلال موقعنا وبدون موافقة ولي الأمر فسنقوم بحذف المعلومات من سجلاتنا.
            </Typography>
            <Typography variant="subtitle1" gutterBottom ref={sectionRefs.fifth}>
              خامساً: استخدام البيانات والانتفاع منها
            </Typography>

            <Typography variant="body1" paragraph>
              البيانات التي يتم تخزينها في المنصة يتم استخدامها للغايات التالية:
            </Typography>

            <Typography variant="body1" paragraph>
              التواصل معك. إذا اتصلت بنا عبر الموقع، سنستخدم المعلومات الشخصية التي تقدمها للرد
              عليك. قد نستخدم أيضًا المعلومات الشخصية للتواصل معك لأغراض أخرى، مثل مشاركة التحديثات
              حول منتجاتنا وخدماتنا أو تقديم العروض ذات الصلة من شركاء خارجيين.
            </Typography>

            <Typography variant="body1" paragraph>
              التحليلات والتخصيص(Personalization). نحن نستخدم المعلومات الشخصية لإجراء الأبحاث
              والتحليلات، بما في ذلك تحسين موقعنا؛ لفهم كيفية تفاعلك مع موقعنا والإعلانات والاتصالات
              معك لتحديد أي من منتجاتنا أو خدماتنا هي الأكثر شعبية، لتحسين موقع منصتنا وحملاتنا
              التسويقية؛ لتخصيص تجربتك؛ لتوفير الوقت عند استخدامك لموقعنا؛ لتخصيص التسويق والإعلان
              الذي نعرضه لك؛ لفهم كيفية استخدامك لموقعنا؛ لتقديم الخدمات، لفهم احتياجات عملائنا بشكل
              أفضل، وتقديم توصيات مخصصة حول منتجاتنا وخدماتنا.
            </Typography>

            <Typography variant="body1" paragraph>
              الإلتزامات القضائية العدلية. قد نوفر إمكانية الوصول إلى معلوماتك بما في ذلك معلومات
              التعريف الشخصية، عندما يُطلب منا قضائياً القيام بذلك، بما في ذلك الامتثال لأمر
              المحكمة، أو التعاون مع تحقيقات الشرطة، أو فيما يتعلق بالإجراءات القانونية الأخرى.
            </Typography>

            <Typography variant="body1" paragraph>
              يجوز لنا أيضًا استخدام المعلومات لأغراض أخرى سيتم الكشف عنها لك في الوقت الذي تجمع فيه
              هذه المعلومات. على سبيل المثال، نحصل على موافقة محددة من العملاء قبل نشر شهادات
              العملاء وتعليقاتهم ومراجعتهم على موقعنا والتي قد تحتوي على معلومات شخصية.
            </Typography>

            <Typography variant="body1" paragraph>
              للبحث العلمي والدراسات، حيث أنه إذا استدعى له الحاجة، فإن البيانات يتم استدعائها بشكل
              تجميعي Aggregated data وتخزينها بطريقة لا يمكن تمييز أو معرفة هوية المستخدم (الطرف
              الثاني أو الطرف الثالث) وذلك لغايات الدراسات و الابحاث العلمية.
            </Typography>

            <Typography variant="body1" paragraph>
              عند توقيعك على الموافقة على سياسة الخصوصية فأنك موافق على أن تستخدم بياناتك كجزء من
              البيانات المجمعة و على صيغة Aggregated data (بشكل تجميعي مع عدم إمكانية تحديد هويتك)
              لغايات الدراسات و البحث العلمي.
            </Typography>

            <Typography variant="body1" paragraph>
              الارشفة الالكترونية للمستخدم للخدمات الطبية.
            </Typography>

            <Typography variant="body1" paragraph>
              تقديم خدمات خاصة ومناسبة للمستخدم.
            </Typography>

            <Typography variant="body1" paragraph>
              خدمات تسويقية.
            </Typography>

            <Typography variant="subtitle1" gutterBottom ref={sectionRefs.sixth}>
              سادساً: التغييرات في سياسة خصوصية هذا الموقع
            </Typography>

            <Typography variant="body1" paragraph>
              قد تقوم منصة Hakeemna.com بتحديث سياسة خصوصية الموقع الإلكتروني وتطبيقاتها في أي وقت
              ولأي سبب، لذلك نشجعك على مراجعة هذه الصفحة بشكل دوري للحصول على أحدث المعلومات حول
              ممارسات الخصوصية لدينا.
            </Typography>

            <Typography variant="subtitle1" gutterBottom ref={sectionRefs.cookies}>
              سياسة cookies.
            </Typography>

            <Typography variant="body1" paragraph>
              منصة Hakeemna.com لا تستخدم أدوات كوكيز في الصفحة، البيانات في حوزة المنصة هي التي
              تقوم انت بتخزينها في النظام وذلك بعد أن تقوم بفتح حساب في المنصة.
            </Typography>

            <Typography variant="body1" paragraph>
              أخر تحديث لسياسة cookies هو في تاريخ 01/09/2024
            </Typography>

            <Typography variant="subtitle1" gutterBottom>
              الخسائر والتعويضات
            </Typography>

            <Typography variant="body1" paragraph>
              المنصة و التطبيقات التابعة لها لا تقدم أي نصائح أو استشارات طبية ولا تبيع أي سلعة من
              طرفها، وعليها اذا وجد محتوى أو رأي فهي من مسؤولية الشخص الذي قام بنشرها، والمنصة ليست
              ملزمة بالملاحقة القانونية لأي ضرر يقع لأحد الأطراف المتضررة من محتوى او استشارة او
              غيرها، ومنه فإن الملاحقة القانونية ودفع تكاليفها والالتزام بالأضرار هي خارج عن نطاق
              مسؤولية المنصة ومالكيها.
            </Typography>
          </Box>
        </Paper>
      </Box>
    </Container>
  );
}
